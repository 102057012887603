import * as ActionType from './appActions';
import Immutable from 'immutable';

let defaultState = Immutable.fromJS({
  library: {},
  config: {},
  privacyPolicyUrl: '',
});

export default function appReducers(state = defaultState, action) {
  switch (action.type) {
    case ActionType.LOADED_LIBRARY:
      return state.merge(action.response);

    case ActionType.LOADED_CONFIGURATION:
      if (action.response && action.response.success) {
        return state.merge({
          config: action.response.config,
          privacyPolicyUrl: String(action.response.privacy_policy_url),
        });
      }
      return state;

    default:
      return state;
  }
}
