import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n, getGitBookUrl, goToURL, goToUrlAndRefresh } from 'utils/i18nUtils';
import './UserMenu.scss';

export default class UserMenu extends Component {
  async clickedMenuRow(rowName) {
    if (rowName === 'logout') {
      await this.props.logout();
      goToUrlAndRefresh('/');
    } else {
      goToURL('/' + rowName);
    }
    this.props.hide();
  }

  render() {
    const { config } = this.props;

    return (
      <div>
        <div className="total-overlay" onClick={() => this.props.hide()} />

        <div className="user-menu">
          <div className="user-menu-row" onClick={() => this.clickedMenuRow('profile')}>
            {i18n('YOUR_PROFILE')}
          </div>
          <div className="user-menu-row" onClick={() => this.clickedMenuRow('settings')}>
            {i18n('SETTINGS')}
          </div>
          <div className="user-menu-row">
            <a href={getGitBookUrl(config, 'help')} target="_blank" rel="noopener noreferrer" className="footer-help">
              {i18n('HELP')}
            </a>
          </div>
          <div className="user-menu-row" onClick={() => this.clickedMenuRow('logout')}>
            {i18n('LOG_OUT')}
          </div>
        </div>
      </div>
    );
  }
}

UserMenu.propTypes = {
  firstPage: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  config: PropTypes.instanceOf(Map),
};
